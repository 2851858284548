<!-- 个人中心 => 办理进度 => 卡片 -->
<template>
    <div class="schedule_card">
        <!-- 标题 -->
        <div class="card_header">
            <div class="card_header_company">{{ computedTime(oItemInfo.updatetime, 'yyyy-MM-dd') }}</div>
            <div :class="['card_header_type', oStatusTitle(oItemInfo.status)]">
                {{ status_type[oItemInfo.status] }}
            </div>
        </div>
        <!-- 内容 -->
        <div class="card_content">
            <ul>
                <li
                    v-for="(obj, i) in oContentList"
                    :key="i"
                >
                    <span class="title">{{ obj.title }}</span>
                    <span class="space">{{ oItemInfo[obj.model] || '无' }}</span>
                </li>
                <li class="remark" v-if="oItemInfo.status === 4">
                    <span class="title">备注: </span>
                    <span class="space">{{ oItemInfo.remarks }}</span>
                </li>
            </ul>
        </div>
        <!-- 底部 -->
        <div class="card_fooder">
            <span v-if="oItemInfo.status === 3 && province !== '2572'" @click="evGoToAnewView">终端选择</span>
            <span v-if="oItemInfo.status === 4 && oItemInfo.is_new !== 0" @click="evGoToProveView">重新填写</span>
        </div>
    </div>
</template>

<script>
// 方法
import { formatDate } from '@/utils/get-page-title.js'

export default {
    props: {
        // 列表数据
        oItemInfo: {
            type: Object
        }
    },
    data () {
        return {
            // 省份
            province: '',
            // 内容列表
            oContentList: [
                { title: '', model: 'real_name' },
                { title: '业务号码: ', model: 'business_mobile' }
            ],
            // 状态
            status_type: {
                '2': '待审核',
                '3': '审核通过',
                '4': '未通过',
                '14': '风控预审'
            }
        }
    },
    methods: {
        // 终端选择 (按钮)
        evGoToAnewView: function () {
            this.$store.dispatch('order/temSetOrderInfo', this.oItemInfo)
            const { old_repay_money: repay_money, id } = this.oItemInfo
            this.$router.push({
                name: 'CusAnew',
                query: { repay_money, id }
            })
        },
        // 重新填写 (按钮)
        evGoToProveView: function () {
            const t_Id = localStorage.getItem('thirdId')
            const is_aut = localStorage.getItem('is_sign')
            this.$router.push({
                'name': 'CusProve',
                'query': { 'third_id': t_Id, 'code': is_aut === '1' ? '0' : '', edit_id: this.oItemInfo.id }
            })
        }
    },
    mounted () {
        sessionStorage.clear()
        this.province = localStorage.getItem('province')
    },
    computed: {
        // 时间格式转换
        computedTime () {
            return function (time, fmt) {
                return formatDate(time, fmt)
            }
        },
        // 状态 title
        oStatusTitle () {
            return function (status) {
                return status === 2 ? '' : status === 3 ? 'pass' : 'not_pass'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.schedule_card {
    padding: 16px;
    margin: 0 auto 34px;
    width: 670px;
    line-height: 66px;
    font-size: 24px;
    box-shadow: 0 0px 20px rgba(0,21,41,.15);
    border-radius: 6px;

    // 头部
    .card_header {
        position: relative;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #EBEBEB;

        &_company {
            color: #333;
            font-size: 30px;
            font-weight: 500;

            &::before {
                display: inline-block;
                content: '';
                margin-right: 12px;
                width: 8px;
                height: 28px;
                background: #F2970D;
                border-radius: 0 8px 8px 0;
                overflow: hidden;
            }
        }

        &_type {
            margin-top: 10px;
            width: 120px;
            height: 36px;
            line-height: 36px;
            color: #479FF2;
            text-align: center;
            background: #E1F0FF;
            border-radius: 30px;

            &.pass {
                color: #01B272;
                background: #E7FFF6;
            }

            &.not_pass {
                color: #FF693F;
                background: #FFE8E2;
            }
        }
    }

    // 内容
    .card_content {
        ul {
            padding: 16px 0 16px 24px;
            margin: 0;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            color: #BDBDBD;
            justify-content: space-between;

            li {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.remark {
                    white-space: pre-wrap;
                    line-height: 36px;
                }

                &:first-child {
                    font-size: 28px;
                    font-weight: bold;
                    width: 350px;
                    color: #333;
                }

                .space {
                    color: #666;
                    word-break: break-all;
                }
            }
        }
    }

    // 底部
    .card_fooder {
        text-align: right;

        span {
            display: inline-block;
            padding: 0 14px;
            width: 170px;
            line-height: 48px;
            color: #fff;
            font-weight: 500;
            border-radius: 100px;
            text-align: center;
            background: linear-gradient(90deg, #F2970C, #FEC56E );
        }
    }
}
</style>
